import {FC, Fragment, useLayoutEffect, useRef, useState} from "react";
import logo from "assets/images/cfl_small_white_logo.png";
import logoFr from "assets/images/cfl_small_white_logo_french.png";
import styled from "styled-components";
import {
	IEmptyItem,
	IFirstItem,
	ISecondItem,
	IWithImageItem,
	Language,
	links,
	LinksType,
	logoLinks,
} from "modules";
import {Img, Item} from "./common";
import {Exist} from "components";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "modules/utils";
import {last} from "lodash";
import {EmptyItem, FirstItem, SecondItem, WithImageItem} from "./Desktop";

const Wrapper = styled.div`
	width: 100%;
	display: none;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	padding: 0 10px;
	max-width: 1270px;
	margin: 0 auto;

	@media (min-width: 769px) {
		display: flex;
	}
`;

const Nav = styled.nav`
	width: 100%;
	margin: 17px 0;
`;

const List = styled.ul`
	display: flex;
	width: 100%;
	list-style: none;
	position: relative;
`;

const DroppedMenu = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 0;
	background: #fff;
	min-width: 100%;
	opacity: 0;
	top: 100%;
	transition: all 0.2s ease-out;
	visibility: hidden;
	z-index: 2;
	overflow: visible;
	padding: 20px;
`;

const DroppedMenuLink = styled.a`
	font-size: 18px;
	margin-bottom: 20px;
	color: #252525;
	font-family: Khand, sans-serif;
	padding: 0;
	display: block;
	font-weight: 500;
	letter-spacing: 0.05em;
	line-height: 1;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	transition: none;
	white-space: nowrap;

	&.hidden {
		display: none;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		color: ${({theme}) => theme.color.headerSubLinkHover};
	}
`;

const DotsItem = styled(Item)<{$show: boolean}>`
	font-size: 30px;
	line-height: 10px;
	padding: 12px 16px 11px;
	position: relative;
	align-items: center;
	justify-content: center;
	display: ${({$show}) => ($show ? "flex !important" : "none")};

	.dots {
		position: relative;
		top: -8px;
	}

	&:hover {
		${DroppedMenu} {
			visibility: visible;
			opacity: 1;
		}
	}
`;

const DOTS_WIDTH = 110;

const icons: Record<string, string> = {
	en: logo,
	fr: logoFr,
};

export const DesktopMenu: FC = () => {
	const {width} = useWindowSize();
	const ref = useRef<HTMLUListElement>(null);
	const dropMenuRef = useRef<HTMLDivElement>(null);
	const [showDropList, setShowDropList] = useState(false);
	const {t, i18n} = useTranslation();
	const lang = i18n.language;
	const icon = icons[lang] || logo;
	const localeUrls = links[lang as Language] || links.en;
	const logoLink = logoLinks[lang as Language] || logoLinks[Language.English];

	useLayoutEffect(() => {
		if (!ref.current || !width) {
			return;
		}

		const element = ref.current;
		const list = Array.from(element.children);

		element.querySelectorAll(".hidden").forEach((el) => el.classList.remove("hidden"));
		list.forEach((el) => el.classList.remove("hidden", "reverse"));

		const clientWidth = element.clientWidth - DOTS_WIDTH; // width box with dot
		const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;

		setShowDropList(hasHorizontalScrollbar);

		let sumWidth = 0;

		list.forEach((item, i) => {
			sumWidth += item.clientWidth;

			if (sumWidth >= clientWidth) {
				item.classList.add("hidden");
			} else {
				dropMenuRef.current?.children[i]?.classList.add("hidden");
			}
		});

		const lastVisibleChild = last(list.filter((el) => !el.classList.contains("hidden")));

		if (lastVisibleChild) {
			lastVisibleChild.classList.add("reverse");
		}
	}, [width]);

	return (
		<Wrapper>
			<a href={logoLink}>
				<Img src={icon} alt="CFL" />
			</a>

			<Nav>
				<List ref={ref}>
					{localeUrls.map((el, i) => (
						<Fragment key={`${el.url}-${i}`}>
							<Exist when={el.type === LinksType.FIRST}>
								<FirstItem {...(el as IFirstItem)} />
							</Exist>

							<Exist when={el.type === LinksType.SECOND}>
								<SecondItem {...(el as ISecondItem)} />
							</Exist>

							<Exist when={el.type === LinksType.EMPTY}>
								<EmptyItem {...(el as IEmptyItem)} />
							</Exist>

							<Exist when={el.type === LinksType.WITH_IMAGE}>
								<WithImageItem {...(el as IWithImageItem)} />
							</Exist>
						</Fragment>
					))}

					<DotsItem $show={showDropList}>
						<div className="dots">...</div>

						<DroppedMenu ref={dropMenuRef}>
							{localeUrls.map(({url, title}, i) => (
								<DroppedMenuLink key={`${url}-${i}`} href={url}>
									{t(title)}
								</DroppedMenuLink>
							))}
						</DroppedMenu>
					</DotsItem>
				</List>
			</Nav>
		</Wrapper>
	);
};
