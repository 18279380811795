import {CustomDetector} from "i18next-browser-languagedetector";
import {Language} from "modules/enums";

export default {
	name: "languageDetectorByUrl",

	lookup() {
		if (window.location.origin.includes("lcf")) {
			return Language.French;
		}

		return Language.English;
	},
} as CustomDetector;
