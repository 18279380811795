import {FC, Fragment, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
	ButtonsBox,
	Modal,
	ModalContent,
	ModalIcon,
	ModalInner,
	ModalText,
	ModalTitle,
	Overlay,
} from "./common";
import {hideSuccessModal} from "modules/actions";
import {getSuccessModalMessage} from "modules/selectors";
import {CloseButton, PrimaryButton} from "components";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import icon from "assets/images/icons/icon-success.svg";

export const SuccessModal: FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const message = useSelector(getSuccessModalMessage);

	const handleClose = useCallback(() => {
		dispatch(hideSuccessModal());
	}, [dispatch]);

	if (!message) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={handleClose} />
			<Modal>
				<ModalInner>
					<ModalContent>
						<CloseButton onClick={handleClose}>
							<CloseIcon />
						</CloseButton>
						<ModalIcon src={icon} alt="Success" />
						<ModalTitle>{t("modal.success.title")}</ModalTitle>
						<ModalText>{message}</ModalText>
						<ButtonsBox>
							<PrimaryButton onClick={handleClose}>
								{t("modal.success.button")}
							</PrimaryButton>
						</ButtonsBox>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
