export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const STORAGE_VERSION = 1;

export const TITLE_FR = "Prédictions d’avant-saison LCF";
export const FAVICON_FR = `${process.env.PUBLIC_URL}/favicon-fr.ico`;

export const logoLinks = {
	en: "https://cfl.ca/",
	fr: "https://www.lcf.ca/",
};

export * from "./socialNetworkLinks";
export * from "./routing";
