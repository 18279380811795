import {createReducer} from "redux-act";
import {requestData, requestDataSuccess} from "modules/actions";
import {IsFriendly, RequestState} from "modules/enums";

export interface IOption {
	id: number;
	title: Record<string, string>;
}

export interface IQuestion {
	id: number;
	position: number;
	title: {
		en: string;
		fr: string;
	};
	options: IOption[];
}

const {NO, YES} = IsFriendly;

export interface ISponsorData {
	title: Record<string, string>;
	message: Record<string, string>;
	image: Record<string, string>;
	link: string;
	linkText: Record<string, string>;
}

export interface IContest {
	id: number;
	name: string;
	startDate: string;
	endDate: string;
	questions: IQuestion[];
	[NO]: ISponsorData;
	[YES]: ISponsorData;
}

export interface IJSONs {
	requestState: RequestState;
	contest: IContest | null;
}

const initial: IJSONs = {
	requestState: RequestState.IDLE,
	contest: null,
};

const onRequestData = (state: IJSONs) => ({
	...state,
	requestState: RequestState.Requested,
});

const onRequestDataSuccess = (state: IJSONs, payload: IContest) => ({
	...state,
	requestState: RequestState.Received,
	contest: payload,
});

export const jsons = createReducer({}, initial)
	.on(requestData, onRequestData)
	.on(requestDataSuccess, onRequestDataSuccess);
