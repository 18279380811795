import {FC} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import geniusLogo from "assets/images/logo-genius-sports.svg";

const Wrapper = styled.footer`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex: 1;
	padding-top: 60px;
	color: ${({theme}) => theme.color.footer};
	font-size: 12px;
	font-family: "Hind", sans-serif;
`;

const Container = styled.div`
	padding: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({theme}) => theme.background.footer};
	position: relative;

	@media (min-width: 900px) {
		align-items: normal;
		padding: 25px 40px;
		margin: 0 auto;
		flex-direction: row;
	}
`;

const Link = styled.a`
	color: ${({theme}) => theme.color.footerLink};
	margin-top: 8px;

	@media (min-width: 900px) {
		margin-left: 22px;
		margin-top: 0;
	}
`;

const GeniusLogo = styled.div`
	display: flex;
	align-items: center;
	color: #ffffff;
	font-size: 16px;
	margin-top: 16px;

	img {
		margin-left: 10px;
		height: 35px;
	}

	@media (min-width: 900px) {
		margin-top: 0;
		position: absolute;
		top: 16px;
		right: 16px;
		font-size: 14px;

		img {
			height: 30px;
		}
	}

	@media (min-width: 1000px) {
		right: 30px;

		img {
			height: 35px;
		}
	}
`;

const Flex = styled.div`
	display: flex;

	${Link} {
		margin-top: 14px;

		&:first-child {
			margin-right: 20px;

			@media (min-width: 900px) {
				margin-right: 0;
			}
		}

		@media (min-width: 900px) {
			margin-top: 0;
		}
	}

	@media (min-width: 900px) {
		display: block;
	}
`;

export const Footer: FC = () => {
	const {t} = useTranslation();

	return (
		<Wrapper>
			<Container>
				<p>{t("footer.copyright")}</p>

				<Flex>
					<Link href="https://www.cfl.ca/terms-of-use/">{t("footer.terms_of_use")}</Link>
					<Link target="_blank" href="https://www.cfl.ca/privacy-policy/">
						{t("footer.privacy_policy")}
					</Link>
				</Flex>

				<GeniusLogo>
					<div>{t("footer.powered_by")}</div>
					<a href="https://geniussports.com/" target="_blank" rel="noopener noreferrer">
						<img src={geniusLogo} alt="Genius sports" />
					</a>
				</GeniusLogo>
			</Container>
		</Wrapper>
	);
};
