import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import {lazy, StrictMode, Suspense} from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {BASE_URL} from "modules";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Preloader, Layout, HOCi18n, ErrorModal, SuccessModal} from "components";
import {store} from "modules/store";
import {ThemeProvider} from "styled-components";
import {theme} from "assets/css/theme";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://c2b727ad13164958b3aafd9fc2d8db3a@o151969.ingest.sentry.io/6389349",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	allowUrls: ["futures.cfl.ca", "predictionsavantsaison.lcf.ca", ".f2p.media.geniussports.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
	ignoreErrors: [
		"Non-Error promise rejection captured",
		"Abort due to cancellation of share.",
		"AbortError: Share canceled",
	],
});

const Landing = lazy(() => import("pages/Landing"));
const NotFound = lazy(() => import("pages/NotFound"));

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
	<StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<Router basename={BASE_URL}>
					<HOCi18n>
						<Suspense fallback={<Preloader />}>
							<Routes>
								<Route element={<Layout />}>
									<Route path="/" element={<Landing />} />
								</Route>

								<Route path="*" element={<NotFound />} />
							</Routes>
							<ErrorModal />
							<SuccessModal />
						</Suspense>
					</HOCi18n>
				</Router>
			</ThemeProvider>
		</Provider>
	</StrictMode>
);
