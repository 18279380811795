import {createReducer} from "redux-act";
import {IError} from "modules/types";
import {hideErrorModal, hideSuccessModal, showErrorModal, showSuccessModal} from "modules/actions";

export interface IModalsReducer {
	error?: string;
	message?: string;
}

const initialState: IModalsReducer = {
	error: "",
	message: "",
};

const onShowErrorModal = (state: IModalsReducer, {message}: IError) => ({
	...state,
	message: "",
	error: message,
});

const onHideErrorModal = () => ({
	...initialState,
});

const onShowSuccessModal = (state: IModalsReducer, message: string) => ({
	...state,
	error: "",
	message,
});

const onHideSuccessModal = (state: IModalsReducer, message: string) => ({
	...state,
	message: "",
});

export const modals = createReducer<IModalsReducer>({}, initialState)
	.on(showErrorModal, onShowErrorModal)
	.on(hideErrorModal, onHideErrorModal)
	.on(showSuccessModal, onShowSuccessModal)
	.on(hideSuccessModal, onHideSuccessModal);
