import {FC, useLayoutEffect} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Footer, Header} from "components";
import {FAVICON_FR, TITLE_FR} from "modules";
import bgc from "assets/images/bg.jpg";
import {Outlet} from "react-router-dom";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	position: relative;
	min-height: 100vh;
	background: url(${bgc}) no-repeat center center;
	background-attachment: fixed;
	background-size: cover;
`;

export const Layout: FC = () => {
	const {i18n} = useTranslation();

	useLayoutEffect(() => {
		if (i18n.language === "fr") {
			const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

			if (link) {
				link.href = FAVICON_FR;
				document.title = TITLE_FR;
			}
		}
	}, [i18n.language]);

	return (
		<Wrapper>
			<Header />
			<Outlet />
			<Footer />
		</Wrapper>
	);
};
