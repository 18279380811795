import {FormField} from "./Input";
import styled from "styled-components";

export const CheckboxFormField = styled(FormField)`
	&.MuiFormControl-root {
		height: auto;
		margin-bottom: 16px;
	}
`;

export const CheckboxLabel = styled.span`
	font-family: "Gotham", sans-serif;
	font-size: 14px;
	line-height: 18px;
	color: ${({theme}) => theme.color.primary};
`;

export const CheckBoxLink = styled.a`
	text-decoration: underline;
`;
