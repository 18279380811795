import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {RequestState} from "modules/enums";

export const getJSONs = (state: IStore) => state.jsons;

export const getIsLoading = createSelector(
	getJSONs,
	({requestState}) => requestState !== RequestState.Received
);

export const getContest = createSelector(getJSONs, ({contest}) => contest);
