import {call, put} from "typed-redux-saga";
import {requestDataFailed, requestDataSuccess, showErrorModal} from "modules/actions";
import {Api, ApiError} from "modules/utils";
import {first} from "lodash";

export const requestDataSaga = function* () {
	try {
		const result = yield* call(Api.JSON.contests);
		const contest = first(result.contests);

		if (contest) {
			yield* put(
				requestDataSuccess({
					...contest,
					questions: contest.questions.sort((a, b) => a.position - b.position),
				})
			);
		}
	} catch (err) {
		yield* put(requestDataFailed());

		if (err instanceof ApiError) {
			yield* put(showErrorModal(err));
		}
	}
};
