import {FC, SyntheticEvent, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {get, toNumber} from "lodash";
import styled from "styled-components";
import {Select, SelectChangeEvent} from "@mui/material";
import {getContest, getGameState} from "modules/selectors";
import {ActiveStep} from "modules";
import {Form, FormWrapper, MenuItem, PrimaryButton, SelectContainer, SelectTitle} from "components";
import {clearGameError, setActiveStep, setGameData} from "modules/actions";
import {useTranslation} from "react-i18next";
import {createConnextraScriptTag, getValueByLang} from "modules/utils";

const Button = styled(PrimaryButton)`
	max-width: 300px;
`;

const Question = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 300px;
`;

export const PredictorForm: FC = () => {
	const dispatch = useDispatch();
	const ref = useRef<HTMLFormElement>(null);
	const {t, i18n} = useTranslation();
	const contest = useSelector(getContest);
	const {gameData} = useSelector(getGameState);
	const [isValid, setIsValid] = useState(!!gameData);
	const isDisabled = !isValid;
	const lang = i18n.language;

	useEffect(() => {
		createConnextraScriptTag(
			"https://zz.connextra.com/dcs/tagController/tag/8ce716f59752/f2p_preseasonfutures_homepage"
		);
	}, []);

	const handleSubmit = useCallback(
		(e: SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();

			if (!ref.current || !contest) {
				return;
			}

			const values = contest.questions.map((question) => {
				return {
					questionId: question.id,
					optionId: toNumber(
						(ref.current![`question-${question.id}`] as HTMLInputElement).value
					),
				};
			});

			dispatch(setGameData(values));
			dispatch(setActiveStep(ActiveStep.EMAIL));
		},
		[dispatch, contest]
	);

	const handleOnChange = useCallback(
		(e: SelectChangeEvent<unknown>) => {
			if (!ref.current || !contest) {
				return;
			}

			setTimeout(() => {
				const values = contest.questions.map((question) => {
					return (ref.current![`question-${question.id}`] as HTMLInputElement).value;
				});

				const isValid = values.every((value) => !!value);
				setIsValid(isValid);
				dispatch(clearGameError({}));
			});
		},
		[dispatch, setIsValid, contest]
	);

	const getInitialValue = useCallback(
		(id: number) => {
			return get(
				gameData?.find(({questionId}) => questionId === id),
				"optionId",
				""
			);
		},
		[gameData]
	);

	if (!contest) {
		return null;
	}

	return (
		<FormWrapper>
			<Form ref={ref} onSubmit={handleSubmit}>
				{contest.questions.map((question, i) => (
					<Question key={question.id}>
						<SelectTitle>
							{`${i + 1}. ${getValueByLang(question.title, lang)}`}
						</SelectTitle>
						<SelectContainer hiddenLabel={true}>
							<Select
								variant="filled"
								required={true}
								name={`question-${question.id}`}
								defaultValue={getInitialValue(question.id) || 0}
								onChange={handleOnChange}>
								<MenuItem disabled={true} value={0}>
									{t("prediction.select_answer")}
								</MenuItem>
								{question.options.map(({title, id}) => (
									<MenuItem key={id} value={id}>
										{getValueByLang(title, lang)}
									</MenuItem>
								))}
							</Select>
						</SelectContainer>
					</Question>
				))}

				<Button type="submit" disabled={isDisabled}>
					{t("prediction.next_button")}
				</Button>
			</Form>
		</FormWrapper>
	);
};
