import styled from "styled-components";

export const ErrorText = styled.div`
	max-width: 300px;
	width: 100%;
	font-size: 14px;
	margin: 8px 0;
	color: ${({theme}) => theme.color.error};
	font-weight: 600;
`;

export const Title = styled.div`
	font-family: "Hind", sans-serif;
	font-weight: 700;
	width: 100%;
	font-size: 16px;
	margin-bottom: 16px;
`;

export const SubTitle = styled(Title)`
	font-weight: 400;
	color: ${({theme}) => theme.color.primary};
`;
