import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import {CANCEL} from "redux-saga";
import {API_URL, JSON_URL} from "modules/constants";
import {IApiResponse} from "modules/types";
import ApiError from "./ApiError";
import {IContest} from "modules/reducers";
import {ISaveUserAnswers} from "modules/actions";
import {IsFriendly} from "modules/enums";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({
	response,
	message = "Network error",
	...rest
}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) =>
		ApiError.CHECK(err as IApiResponse, response?.status)
	);
};

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

interface ISaveUserAnswerPayload extends ISaveUserAnswers {
	answers: number[];
}

type TIsFriendly = IApiResponse<{isoCode: string; status: IsFriendly}>;

export const Api = {
	JSON: {
		contests: () => JSONClient.get<{contests: IContest[]}>("contests.json"),
	},
	Predictor: {
		save: (params: ISaveUserAnswerPayload) =>
			APIClient.post<IApiResponse>("answers/save", params),
		isFriendlyCountry: () => APIClient.get<TIsFriendly>("country/canada_state"),
	},
};

export * from "./ApiError";

export default Api;
