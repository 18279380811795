import {createAction} from "redux-act";
import {ActiveStep, IsFriendly, RequestState} from "modules/enums";
import {IFormError} from "modules/types";
import {IGameData} from "modules/reducers";

export interface ISaveUserAnswers {
	email: string;
	notification: boolean;
	lang: string;
}

export const setActiveStep = createAction<ActiveStep>();
export const clearGameError = createAction<IFormError>();
export const setGameData = createAction<IGameData[]>();

export const saveUserAnswers = createAction<ISaveUserAnswers>();
export const saveUserAnswersSuccess = createAction();
export const saveUserAnswersFailed = createAction<IFormError>();

export const getIsFriendlyCountry = createAction();
export const getIsFriendlyCountrySuccess = createAction<IsFriendly>();
export const getIsFriendlyCountryFailed = createAction();

export const changeRequestState = createAction<RequestState>();
