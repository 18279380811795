import twitter from "assets/images/icons/twitter.svg";
import facebook from "assets/images/icons/facebook.svg";
import instagram from "assets/images/icons/instagram.svg";
import youtube from "assets/images/icons/youtube.svg";
import tiktok from "assets/images/icons/tiktok.svg";

export const socialNetworkLinks = [
	{
		name: "Twitter",
		url: {
			en: "https://twitter.com/CFL",
			fr: "https://twitter.com/LCFca",
		},
		icon: twitter,
	},
	{
		name: "Facebook",
		url: {
			en: "https://www.facebook.com/CFL",
			fr: "https://www.facebook.com/LCF",
		},
		icon: facebook,
	},
	{
		name: "Instagram",
		url: {
			en: "https://instagram.com/cfl",
			fr: "https://instagram.com/cfl",
		},
		icon: instagram,
	},
	{
		name: "YouTube",
		url: {
			en: "https://www.youtube.com/CFL",
			fr: "https://www.youtube.com/CFL",
		},
		icon: youtube,
	},
	{
		name: "Tiktok",
		url: {
			en: "https://www.tiktok.com/@cfl",
			fr: "https://www.tiktok.com/@cfl",
		},
		icon: tiktok,
	},
];
