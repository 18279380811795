import styled from "styled-components";

export const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
`;

export const Modal = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 20px);
	max-height: calc(100% - 20px);
	overflow: auto;
	max-width: 600px;
	min-height: 200px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
`;

export const ModalInner = styled.div`
	position: relative;
	padding: 25px;
`;

export const ModalContent = styled.div`
	color: #343232;
	text-align: center;
`;

export const ButtonsBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	button {
		max-width: 220px;
		margin: 10px auto;
	}

	@media (min-width: 769px) {
		button {
			max-width: 300px;
		}
	}
`;

export const ModalTitle = styled.h4`
	font-size: 24px;
	line-height: 45px;
	letter-spacing: 0.83px;
	margin-bottom: 5px;

	@media (min-width: 769px) {
		font-size: 30px;
	}
`;

export const ModalText = styled.p`
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 30px;

	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

export const ModalIcon = styled.img`
	width: 80px;
	height: auto;
	margin-bottom: 20px;

	@media (max-width: 800px) {
		margin-bottom: 10px;
	}
`;
