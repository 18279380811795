import React from "react";
import styled from "styled-components";
import Spinner from "components/Spinner";

const SPreloader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: #ffffff;

	.loader {
		width: 120px;
		height: 120px;
		transform: rotate(-90deg);
		stroke-linecap: round;
		stroke-width: 4;
		fill: none;
	}

	.loader .internal-circle,
	.loader .external-circle {
		stroke-dashoffset: 0;
		transform-origin: center;
	}

	.loader .internal-circle {
		stroke-dasharray: 187;
		animation: internal 1.5s ease-in-out infinite;
		opacity: 0.4;
	}

	.loader .external-circle {
		stroke-dasharray: 312;
		animation: external 1.5s linear infinite;
		opacity: 0.9;
	}

	@keyframes internal {
		0% {
			stroke-dashoffset: 187;
		}
		25% {
			stroke-dashoffset: 80;
		}
		100% {
			stroke-dashoffset: 187;
			transform: rotate(360deg);
		}
	}

	@keyframes external {
		0% {
			stroke-dashoffset: 312;
			transform: rotate(70deg);
		}
		60% {
			stroke-dashoffset: -312;
		}
		100% {
			stroke-dashoffset: -312;
			transform: rotate(450deg);
		}
	}
`;

export const Preloader: React.FC = () => {
	return (
		<SPreloader>
			<Spinner color="#C51A20" />
		</SPreloader>
	);
};
