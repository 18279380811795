import {useEffect, useState} from "react";
import {debounce} from "lodash";

export const useWindowSize = (delay = 300) => {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		const debounced = debounce(handleResize, delay);
		window.addEventListener("resize", debounced);
		handleResize();

		return () => window.removeEventListener("resize", debounced);
	}, [delay]);

	return windowSize;
};
