import styled from "styled-components";
import {
	FormControl,
	InputLabel,
	Select as DefaultSelect,
	MenuItem as DefaultMenuItem,
} from "@mui/material";
import {ReactComponent as ArrowBackIosIcon} from "assets/images/icons/arrow-down.svg";

export const SelectIcon = styled(ArrowBackIosIcon)`
	margin-right: 8px;

	&& {
		top: 14px;
	}
`;

export const SelectLabel = styled(InputLabel)`
	&&.MuiInputLabel-root {
		text-align: left;
		color: #a3a3a3;
	}
`;

export const SelectContainer = styled(FormControl)`
	text-align: left;

	&.MuiFormControl-root {
		width: 100%;
		margin-bottom: 26px;

		//input {
		//	padding: 13px;
		//}

		label {
			&[data-shrink="false"] {
				top: -2px;
			}
		}

		@media (min-width: 769px) {
			margin-top: 0;
			max-width: 300px;
		}
	}
`;

export const Select = styled(DefaultSelect)`
	width: 100%;
	background-color: #ffffff;

	&& label {
		&.Mui-disabled {
			opacity: 0.3;
		}

		color: #a3a3a3;
	}

	& .MuiInput-underline:after {
		border-bottom-color: #d9d9d9;
	}

	&.MuiOutlinedInput-root {
		&.Mui-disabled {
			opacity: 0.3;
		}

		.MuiSelect-select {
			//padding: 13px;
		}

		& fieldset {
			border-color: #d9d9d9;
		}

		&:hover fieldset {
			border-color: #d9d9d9;
		}

		&.Mui-focused fieldset {
			border-color: #d9d9d9;
		}
	}
`;

export const SelectTitle = styled.div`
	font-family: "Hind", sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	width: 100%;
	font-size: 16px;
	margin-bottom: 8px;
	text-indent: -15px;
	padding-left: 15px;
`;

export const MenuItem = styled(DefaultMenuItem)`
	&.MuiMenuItem-root {
		font-family: "Hind", sans-serif;
	}
`;
