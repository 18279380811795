import {FormControl, TextField} from "@mui/material";
import styled from "styled-components";

export const Input = styled(TextField)`
	width: 100%;
	background-color: #ffffff;

	&& label {
		&.Mui-disabled {
			opacity: 0.3;
		}

		color: #a3a3a3;
	}

	& .MuiInput-underline:after {
		border-bottom-color: #d9d9d9;
	}

	& .MuiOutlinedInput-root {
		&.Mui-disabled {
			opacity: 0.3;
		}

		& fieldset {
			border-color: #d9d9d9;
		}

		&:hover fieldset {
			border-color: #d9d9d9;
		}

		&.Mui-focused fieldset {
			border-color: #d9d9d9;
		}
	}
`;

export const FormField = styled(FormControl)`
	&.MuiFormControl-root {
		width: 100%;
		margin-bottom: 16px;

		input {
			padding: 13px;
		}

		label {
			&[data-shrink="false"] {
				top: -2px;
			}
		}
	}
`;
