import styled, {css} from "styled-components";
import {IButtonStyles} from "modules/types/theme";

export const BaseButton = styled.button`
	background: transparent;
	appearance: none;
	outline: none;
	display: block;
	padding: 0 15px;
	background-clip: padding-box;
	font-weight: 700;
	margin: 0;
	cursor: pointer;
	font-size: 16px;
	width: 100%;
	transition: all 0.2s linear;
	border: 1px solid;
	height: 44px;
	font-family: "Khand", sans-serif;
`;

export const buttonStyles = (styles: IButtonStyles) => {
	const {
		color,
		background,
		borderColor,
		disabledColor,
		disabledBackground,
		disabledBorderColor,
		hoverColor,
		hoverBackground,
		hoverBorderColor,
		fontWeight,
	} = styles;

	return css`
		color: ${color};
		background-color: ${background};
		border-color: ${borderColor};
		font-weight: ${fontWeight};
		font-size: 16px;

		&:hover:not(:disabled) {
			color: ${hoverColor};
			background-color: ${hoverBackground};
			border-color: ${hoverBorderColor};
		}
		&:disabled {
			color: ${disabledColor};
			background-color: ${disabledBackground};
			border-color: ${disabledBorderColor};
			cursor: initial;
		}
	`;
};
