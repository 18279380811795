import {FC} from "react";
import styled from "styled-components";
import {MobileMenu} from "./MobileMenu";
import {DesktopMenu} from "./DesktopMenu";
import bgc from "assets/images/header-bckg.jpeg";

const Wrapper = styled.header`
	width: 100%;
	color: ${({theme}) => theme.color.header};
	background-color: ${({theme}) => theme.background.header};

	@media (min-width: 769px) {
		background-image: url(${bgc});
		background-repeat: no-repeat;
		background-position: bottom;
	}
`;

export const Header: FC = () => {
	return (
		<Wrapper>
			<DesktopMenu />
			<MobileMenu />
		</Wrapper>
	);
};
