import {
	ChangeEvent,
	FC,
	Fragment,
	MouseEvent,
	SyntheticEvent,
	useCallback,
	useEffect,
	useState,
} from "react";
import styled from "styled-components";
import {
	BurgerButton,
	Img,
	Navigation,
	NavigationList,
	NavigationWrapper,
	SearchInput,
} from "./common";
import {useIsMobile} from "modules/utils";
import {Language, links, logoLinks, socialNetworkLinks} from "modules";
import {Item} from "./Mobile";
import {useTranslation} from "react-i18next";
import {ReactComponent as BurgerIcon} from "assets/images/icons/burger.svg";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import logo from "assets/images/cfl_small_white_logo.png";
import logoFr from "assets/images/cfl_small_white_logo_french.png";

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;

	div:last-child {
		width: 40px;
	}

	@media (min-width: 769px) {
		display: none;
	}
`;

const SubNavigation = styled.div`
	padding: 10px 30px 30px;
`;

const SubNavigationTitle = styled.h3`
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.025em;
	text-transform: uppercase;
`;

const SocialLinks = styled.div`
	display: flex;
	align-items: center;
	margin-top: 8px;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #2b2b2b;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		margin: 10px 8px 0 0;
		transition: all 0.2s ease-out;

		img {
			width: 16px;
		}

		&:hover {
			transform: scale(1.15);
			background-color: ${({theme}) => theme.background.mobileIconHover};
		}
	}
`;

const Copyright = styled.div`
	padding: 0 30px;
	color: #404040;
	font-size: 12px;
`;

const icons: Record<string, string> = {
	en: logo,
	fr: logoFr,
};

const getSocialNetworkUrl = (url: Record<string, string>, lang: string) => {
	return url[lang] || url[Language.English];
};

export const MobileMenu: FC = () => {
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const {isMobile} = useIsMobile();
	const [value, setValue] = useState("");
	const {t, i18n} = useTranslation();
	const lang = i18n.language;
	const icon = icons[lang] || logo;
	const localeUrls = links[lang as Language] || links[Language.English];
	const logoLink = logoLinks[lang as Language] || logoLinks[Language.English];

	useEffect(() => {
		if (!isMobile && isOpenMenu) {
			setIsOpenMenu(false);
		}

		const rootElement = document.querySelector("body");

		if (rootElement) {
			rootElement.style.overflow = isOpenMenu ? "hidden" : "initial";
		}
	}, [isMobile, isOpenMenu]);

	const toggleHandler = useCallback(() => {
		setIsOpenMenu((prev) => !prev);
	}, [setIsOpenMenu]);

	const clickHandler = useCallback((e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	}, []);

	const submitHandler = useCallback(
		(e: SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			const {search}: Record<string, HTMLInputElement> = e.currentTarget;

			window.location.href = `${logoLink}search/?q=${search.value}`;
		},
		[logoLink]
	);

	const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	}, []);

	return (
		<Fragment>
			<Wrapper>
				<BurgerButton onClick={toggleHandler}>
					{isOpenMenu ? <CloseIcon /> : <BurgerIcon />}
				</BurgerButton>

				<a href={logoLink}>
					<Img src={icon} alt="CFL" />
				</a>

				<div />
			</Wrapper>

			<NavigationWrapper isOpen={isOpenMenu} onClick={toggleHandler}>
				<Navigation isOpen={isOpenMenu} onClick={clickHandler}>
					<form onSubmit={submitHandler}>
						<SearchInput name="search" value={value} onChange={changeHandler} />
					</form>

					<NavigationList>
						{localeUrls.map((el, i) => (
							<Item key={`${el.url}-${i}`} {...el} />
						))}
					</NavigationList>

					<SubNavigation>
						<SubNavigationTitle>{t("navigation.follow_cfl")}</SubNavigationTitle>
						<SocialLinks>
							{socialNetworkLinks.map(({url, name, icon}) => (
								<a key={name} href={getSocialNetworkUrl(url, lang)}>
									<img src={icon} alt={name} />
								</a>
							))}
						</SocialLinks>
					</SubNavigation>

					<Copyright>
						<p>{t("navigation.copyright")}</p>
					</Copyright>
				</Navigation>
			</NavigationWrapper>
		</Fragment>
	);
};
