import styled from "styled-components";
import {IconButton} from "@mui/material";

export const CloseButton = styled(IconButton)`
	&& {
		color: #303030;
		top: 8px;
		right: 8px;
		position: absolute;
	}
`;
