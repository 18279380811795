import {FC, Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getErrorModalMessage} from "modules/selectors";
import {hideErrorModal} from "modules/actions";
import icon from "assets/images/icons/icon-warning.svg";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {CloseButton, ModalIcon, PrimaryButton} from "components";
import {
	ButtonsBox,
	Modal,
	ModalContent,
	ModalInner,
	Overlay,
	ModalText,
	ModalTitle,
} from "components/Modals";

export const ErrorModal: FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const errorMessage = useSelector(getErrorModalMessage);

	const handleClose = useCallback(() => {
		dispatch(hideErrorModal());
	}, [dispatch]);

	if (!errorMessage) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={handleClose} />
			<Modal>
				<ModalInner>
					<ModalContent>
						<CloseButton onClick={handleClose}>
							<CloseIcon />
						</CloseButton>
						<ModalIcon src={icon} alt="Error" />
						<ModalTitle>{t("modal.error.title")}</ModalTitle>
						<ModalText>{t(errorMessage)}</ModalText>
						<ButtonsBox>
							<PrimaryButton onClick={handleClose}>
								{t("modal.error.button")}
							</PrimaryButton>
						</ButtonsBox>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
