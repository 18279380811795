import {FC, Fragment, PropsWithChildren, useEffect, useState} from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {ENV, JSON_URL, Language} from "modules";
import {Preloader} from "components";
import languageDetectorByUrl from "modules/utils/common/languageDetector";

const languageDetector = new LanguageDetector();
languageDetector.addDetector(languageDetectorByUrl);

export const HOCi18n: FC<PropsWithChildren<unknown>> = ({children}) => {
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		const userLang = (navigator?.languages[0] || navigator.language).split("-")[0];

		if (userLang === Language.English && window.location.origin.includes("lcf.")) {
			window.location.href = "https://futures.cfl.ca/";
		}

		if (userLang === Language.French && window.location.origin.includes("cfl.")) {
			window.location.href = "https://predictionsavantsaison.lcf.ca/";
		}

		void i18n
			.use(Backend)
			.use(initReactI18next)
			.use(languageDetector)
			.init(
				{
					/**
					 * i18nextBrowserLanguageDetector
					 */
					detection: {
						order: [
							"languageDetectorByUrl",
							"cookie",
							"localStorage",
							"sessionStorage",
							"navigator",
						],
					},
					supportedLngs: ["en", "fr"],
					backend: {
						crossDomain: true,
						loadPath: `${JSON_URL}langs/{{lng}}.json`,
					},
					fallbackLng: "en",
					debug: ENV !== "production",
					keySeparator: ".",
					interpolation: {
						escapeValue: false,
						formatSeparator: ",",
					},
				},
				() => {
					setInitialized(true);
				}
			);
	}, []);

	return initialized ? <Fragment>{children}</Fragment> : <Preloader />;
};
