import {useEffect, useState} from "react";

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			mediaMatch.addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				mediaMatch.removeEventListener("change", handler);
			}
		};
	});

	return matches;
};
