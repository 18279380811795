import styled from "styled-components";
import {
	Item,
	SubLink,
	SubLinkItem,
	SubLinkList,
	SubLinks,
	SubLinksItem,
	SubLinksItemTitle,
} from "components/Header/common";
import {FC} from "react";
import {IEmptyItem, IFirstItem, ISecondItem, IWithImageItem} from "modules";
import {useTranslation} from "react-i18next";
import arrow from "assets/images/icons/main-nav-arrow-gray.png";
import {useActiveLink} from "modules/utils";

const Link = styled.a`
	display: block;
	padding: 12px 16px 11px;
	white-space: nowrap;
	text-transform: uppercase;

	&.active:after {
		border-bottom: 1px solid #737373;
		content: "";
		display: block;
		position: relative;
		bottom: -4px;
	}
`;

const LinkWithArrow = styled(Link)`
	background-image: url(${arrow});
	background-repeat: no-repeat;
	background-position: right 10px center;
	padding: 12px 20px 11px 16px;
`;

const SecondItemWrapper = styled(Item)`
	position: static;

	${SubLinks} {
		width: 100%;
	}

	img {
		max-width: 100%;
	}
`;

export const SecondItem: FC<ISecondItem> = (el) => {
	const {t} = useTranslation();
	const className = useActiveLink(el.url);

	return (
		<SecondItemWrapper id={el.title}>
			<LinkWithArrow href={el.url} className={className}>
				{t(el.title)}
			</LinkWithArrow>

			<SubLinks>
				{el.subLinks.map(({title, urls}) => (
					<SubLinksItem key={title}>
						<SubLinksItemTitle>{t(title)}</SubLinksItemTitle>

						<SubLinkList>
							{urls.map(({url, title}) => (
								<SubLinkItem key={title}>
									<SubLink href={url}>{t(title)}</SubLink>
								</SubLinkItem>
							))}
						</SubLinkList>
					</SubLinksItem>
				))}
			</SubLinks>
		</SecondItemWrapper>
	);
};

export const FirstItem: FC<IFirstItem> = (el) => {
	const {t} = useTranslation();
	const className = useActiveLink(el.url);

	return (
		<Item id={el.title}>
			<LinkWithArrow href={el.url} className={className}>
				{t(el.title)}
			</LinkWithArrow>
			<SubLinks>
				<SubLinksItem>
					<SubLinkList>
						{el.subLinks.map(({title, url}) => (
							<SubLinkItem key={title}>
								<SubLink href={url}>{t(title)}</SubLink>
							</SubLinkItem>
						))}
					</SubLinkList>
				</SubLinksItem>
			</SubLinks>
		</Item>
	);
};

export const EmptyItem: FC<IEmptyItem> = (el) => {
	const {t} = useTranslation();
	const className = useActiveLink(el.url);

	return (
		<Item id={el.title}>
			<Link href={el.url} className={className}>
				{t(el.title)}
			</Link>
		</Item>
	);
};

export const WithImageItem: FC<IWithImageItem> = (el) => {
	const {t} = useTranslation();
	const className = useActiveLink(el.url);

	return (
		<SecondItemWrapper id={el.title}>
			<LinkWithArrow href={el.url} className={className}>
				{t(el.title)}
			</LinkWithArrow>

			<SubLinks>
				{el.subLinks.map(({title, urls, withLine}) => (
					<SubLinksItem key={title} withLine={withLine}>
						<SubLinksItemTitle>{t(title)}</SubLinksItemTitle>

						<SubLinkList>
							{urls.map(({url, title}) => (
								<SubLinkItem key={url}>
									<SubLink href={url}>{t(title)}</SubLink>
								</SubLinkItem>
							))}
						</SubLinkList>
					</SubLinksItem>
				))}

				<SubLinksItem>
					<SubLink href={el.imageUrl}>
						<img src={el.image} alt={el.title} />
					</SubLink>
				</SubLinksItem>
			</SubLinks>
		</SecondItemWrapper>
	);
};
