import styled from "styled-components";
import {IconButton} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {FC, InputHTMLAttributes} from "react";
import {useTranslation} from "react-i18next";
import {Exist} from "components";

export const Img = styled.img`
	height: 60px;
`;

export const SubLinkList = styled.ul`
	display: flex;
	flex-direction: column;
	padding: 0;
	list-style: none;
`;

export const SubLinkItem = styled.li`
	margin-top: 10px;
`;

export const SubLinksItem = styled.li<{withLine?: boolean}>`
	display: block;
	margin: 0;
	padding: 5px 0 0;
	list-style: none;
	background: none;
	min-width: 25%;
	font-size: 16px;
	border-left: ${({withLine}) => (withLine ? "1px solid #1c1c1c" : "none")};
	padding-left: ${({withLine}) => (withLine ? "10px" : "0")};
	height: 100%;
`;

export const SubLinksItemTitle = styled.div`
	text-transform: uppercase;
	color: ${({theme}) => theme.color.headerSubLinksItemTitle};
	font-weight: 700;
`;

export const SubLink = styled.a`
	font-weight: 400;
	letter-spacing: 0.025em;
	color: ${({theme}) => theme.color.headerSubLink};
	text-transform: uppercase;

	&:hover {
		color: ${({theme}) => theme.color.headerSubLinkHover};
	}
`;

export const SubLinks = styled.ul`
	display: flex;
	font-family: "Hind", sans-serif;
	visibility: hidden;
	background: #fff;
	border: 1px solid #c9c8c9;
	border-top: none;
	color: #1c1c1c;
	padding: 15px 20px 20px;
	position: absolute;
	z-index: 99;
	left: 0;
	margin: 0;
	list-style: none;
	box-sizing: border-box;
	transform: translate3d(0, 20px, 0);
	transition: all 0.2s ease-out;
	min-width: 300px;
	opacity: 0;
`;

export const Item = styled.li`
	font-size: 18px;
	font-family: "Khand", sans-serif;
	font-weight: 500;
	letter-spacing: 0.05em;
	line-height: 1;
	z-index: 2;
	position: relative;

	&:hover {
		background: ${({theme}) => theme.background.headerLinkHover};
		color: ${({theme}) => theme.color.headerLinkHover};

		${SubLinks} {
			visibility: visible;
			opacity: 1;
			transform: translate3d(0, -1px, 0);
		}
	}

	&.hidden {
		display: none;
	}

	&.reverse {
		${SubLinks} {
			right: 0;
			left: auto;
		}
	}
`;

export const BurgerButton = styled(IconButton)`
	&& {
		color: #ffffff;

		svg {
			width: 28px;
			height: 28px;
		}

		@media (min-width: 769px) {
			display: none;
		}
	}
`;

export const NavigationWrapper = styled.div<{isOpen: boolean}>`
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100vw;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 100;
	text-align: left;
	overflow: hidden;
	animation: ${({isOpen}) => (isOpen ? "showMenu" : "hideMenu")} forwards 0.3s ease-out;

	@keyframes showMenu {
		0% {
			display: block;
			background-color: #1c1c1c00;
		}

		99% {
			background-color: #1c1c1c7f;
		}

		100% {
			background-color: #1c1c1c7f;
		}
	}

	@keyframes hideMenu {
		0% {
			display: block;
		}

		99% {
			display: none;
			width: 100%;
		}

		100% {
			display: none;
			width: 0;
		}
	}
`;

export const Navigation = styled.div<{isOpen: boolean}>`
	width: 270px;
	padding-bottom: 20px;
	transition: transform 0.3s ease-out;
	background: ${({theme}) => theme.background.mobileNavigation};
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	transform: ${({isOpen}) => (isOpen ? "translateZ(0)" : "translate3d(-270px, 0, 0)")};
`;

const SearchInputWrapper = styled.div`
	position: relative;
	width: 100%;
`;

const SearchInputComponent = styled.input`
	background: #1c1c1c;
	border: 0;
	border-bottom: 1px solid #303030;
	color: #fff;
	height: 56px;
	font-family: Khand, sans-serif;
	font-size: 18px;
	font-weight: 600;
	outline: 0;
	text-align: center;
	width: 100%;
	padding: 1px 10px;
`;

const SearchInputPlaceholder = styled.div`
	pointer-events: none;
	white-space: nowrap;
	display: flex;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #646464;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 18px;

	svg {
		margin-right: 10px;
	}
`;

export const SearchInput: FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
	const {t} = useTranslation();

	return (
		<SearchInputWrapper>
			<SearchInputComponent {...props} />
			<Exist when={!props.value}>
				<SearchInputPlaceholder>
					<SearchIcon />
					{t("navigation.search")}
				</SearchInputPlaceholder>
			</Exist>
		</SearchInputWrapper>
	);
};

export const NavigationList = styled.div`
	padding: 20px 0;
`;
