import {FC, SyntheticEvent, useCallback} from "react";
import {useSelector} from "react-redux";
import {getContest, getIsFriendlyCountryState} from "modules/selectors";
import {Form, FormWrapper, PrimaryButton, SecondaryButton, Title} from "components";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {GAME_ZONE_URL, IMAGES_URL} from "modules";
import {getValueByLang} from "modules/utils";

const MainTitle = styled.h2`
	font-family: "Hind", sans-serif;
	font-weight: 700;
	width: 100%;
	font-size: 18px;
	margin-bottom: 16px;
`;

const Image = styled.img`
	padding: 0 10px;
	width: 100%;
	margin-bottom: 20px;
`;

const Buttons = styled.div`
	width: 100%;

	& > *:first-child {
		display: block;
		margin-bottom: 20px;
	}
`;

export const SponsorForm: FC = () => {
	const {t, i18n} = useTranslation();
	const contest = useSelector(getContest);
	const isFriendly = useSelector(getIsFriendlyCountryState);
	const lang = i18n.language;

	const handleSubmit = useCallback((e: SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
	}, []);

	if (!contest) {
		return null;
	}

	const data = contest[isFriendly];

	return (
		<FormWrapper>
			<Form onSubmit={handleSubmit}>
				<MainTitle dangerouslySetInnerHTML={{__html: getValueByLang(data.title, lang)}} />

				<Title dangerouslySetInnerHTML={{__html: getValueByLang(data.message, lang)}} />

				<Image src={IMAGES_URL + getValueByLang(data.image, lang)} />

				<Buttons>
					<a href={data.link}>
						<PrimaryButton type="button">
							{getValueByLang(data.linkText, lang)}
						</PrimaryButton>
					</a>

					<a href={GAME_ZONE_URL}>
						<SecondaryButton type="button">{t("sponsor.goBack")}</SecondaryButton>
					</a>
				</Buttons>
			</Form>
		</FormWrapper>
	);
};
