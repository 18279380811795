export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum ButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum EventStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum SectionType {
	LEAGUE = "league",
	GENERAL = "general",
}

export enum ShareType {
	FB,
	TW,
	MOBILE,
}

export enum ModalType {
	Error,
	Info,
}

export enum ActiveStep {
	PREDICTION,
	EMAIL,
	SPONSOR,
}

export enum Language {
	English = "en",
	French = "fr",
}

export enum IsFriendly {
	YES = "f",
	NO = "n",
}
