import {createReducer} from "redux-act";
import {
	changeRequestState,
	clearGameError,
	getIsFriendlyCountrySuccess,
	saveUserAnswers,
	saveUserAnswersFailed,
	saveUserAnswersSuccess,
	setActiveStep,
	setGameData,
} from "modules/actions";
import {ActiveStep, IsFriendly, RequestState} from "modules/enums";
import {IFormError} from "modules/types";
import {isEmpty, omit} from "lodash";

export interface IGameData {
	questionId: number;
	optionId: number;
}

interface IGameReducer {
	step: ActiveStep;
	errors: IFormError;
	requestState: RequestState;
	gameData: null | IGameData[];
	isFriendlyCountry: IsFriendly;
}

const initialState: IGameReducer = {
	step: ActiveStep.PREDICTION,
	errors: {},
	requestState: RequestState.IDLE,
	gameData: null,
	isFriendlyCountry: IsFriendly.NO,
};

const onSetActiveStep = (state: IGameReducer, payload: ActiveStep) => ({
	...state,
	step: payload,
});

const onClearGameError = (state: IGameReducer, payload: IFormError) => ({
	...state,
	errors: !isEmpty(payload?.place) ? omit(state.errors, [payload.place]) : {},
});

const onSetGameData = (state: IGameReducer, payload: IGameData[]) => ({
	...state,
	gameData: payload,
});

const onSaveUserAnswers = (state: IGameReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

const onSaveUserAnswersSuccess = (state: IGameReducer) => ({
	...state,
	requestState: RequestState.Received,
	step: ActiveStep.SPONSOR,
});

const onSaveUserAnswersFailed = (state: IGameReducer, payload: IFormError) => ({
	...state,
	requestState: RequestState.Received,
	errors: payload,
});

const onGetIsFriendlyCountrySuccess = (state: IGameReducer, payload: IsFriendly) => ({
	...state,
	isFriendlyCountry: payload,
});

const onChangeRequestState = (state: IGameReducer, payload: RequestState) => ({
	...state,
	requestState: payload,
});

export const game = createReducer<IGameReducer>({}, initialState)
	.on(setActiveStep, onSetActiveStep)
	.on(clearGameError, onClearGameError)
	.on(setGameData, onSetGameData)
	.on(saveUserAnswers, onSaveUserAnswers)
	.on(saveUserAnswersSuccess, onSaveUserAnswersSuccess)
	.on(saveUserAnswersFailed, onSaveUserAnswersFailed)
	.on(getIsFriendlyCountrySuccess, onGetIsFriendlyCountrySuccess)
	.on(changeRequestState, onChangeRequestState);
