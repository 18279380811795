import {createTheme} from "@mui/material";
import {DefaultTheme} from "styled-components";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject = {
	color: {
		primary: "#202020",
		secondary: "#C51A20",
		tertiary: "#ffffff",
		error: "#A82727",
		footer: "#454545",
		footerLink: "#676767",
		header: "#ffffff",
		headerLinkHover: "#1c1c1c",
		headerSubLinksItemTitle: "#b5262f",
		headerSubLink: "#1c1c1c",
		headerSubLinkHover: "#b5262f",
		form: "#C51A20",
	},
	background: {
		primary: "#ffffff",
		secondary: "#C51A20",
		tertiary: "#202020",
		footer: "#000000",
		header: "#191919",
		headerLinkHover: "#f0f0f0",
		mobileNavigation: "#1c1c1c",
		form: "#ffffff",
		mobileIconHover: "#b5262f",
		mobileLinkActive: "#575757",
	},
	button: {
		primary: {
			color: "#ffffff",
			background: "#C51A20",
			borderColor: "#C51A20",
			disabledColor: "#ffffff",
			disabledBackground: "#e6b4b6",
			disabledBorderColor: "#e6b4b6",
			hoverColor: "#fff",
			hoverBackground: "#931A20",
			hoverBorderColor: "#931A20",
			fontWeight: "bold",
		},
		secondary: {
			color: "#202020",
			background: "transparent",
			borderColor: "#C51A20",
			disabledColor: "#b6b6b6",
			disabledBackground: "transparent",
			disabledBorderColor: "#e6b4b6",
			hoverColor: "#202020",
			hoverBackground: "transparent",
			hoverBorderColor: "#931A20",
			fontWeight: "bold",
		},
	},
};

export const theme = createTheme(themeObject) as unknown as DefaultTheme;
type ThemeInterface = typeof themeObject;

declare module "styled-components" {
	// Allows change type of  theme to avoid any
	/* eslint-disable @typescript-eslint/no-empty-interface */

	// eslint-disable-next-line @typescript-eslint/naming-convention
	export interface DefaultTheme extends ThemeInterface {}
}
