import {
	changeRequestState,
	getIsFriendlyCountryFailed,
	getIsFriendlyCountrySuccess,
	saveUserAnswers,
	saveUserAnswersFailed,
	saveUserAnswersSuccess,
	showErrorModal,
} from "modules/actions";
import {call, put, select} from "typed-redux-saga";
import {Api, ApiError, trackIdentify} from "modules/utils";
import {getGameData} from "modules/selectors";
import {RequestState} from "modules/enums";

export const saveUserAnswersSaga = function* ({payload}: ReturnType<typeof saveUserAnswers>) {
	try {
		const gameData = yield* select(getGameData);

		if (!gameData) {
			return;
		}

		yield* call(Api.Predictor.save, {
			...payload,
			answers: gameData.map(({optionId}) => optionId),
		});

		trackIdentify(payload.email);
		yield* put(saveUserAnswersSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			if (err.code === 403) {
				yield* put(saveUserAnswersFailed({"": err.message}));
			} else {
				yield* put(showErrorModal(err));
				yield* put(changeRequestState(RequestState.Received));
			}
		}
	}
};

export const getIsFriendlyCountrySaga = function* () {
	try {
		const result = yield* call(Api.Predictor.isFriendlyCountry);

		yield* put(getIsFriendlyCountrySuccess(result.success.status));
	} catch (err) {
		yield* put(getIsFriendlyCountryFailed());

		if (err instanceof ApiError) {
			yield* put(showErrorModal(err));
		}
	}
};
