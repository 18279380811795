import {FC, SyntheticEvent, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import styled from "styled-components";
import {getContest, getErrors, getGameState} from "modules/selectors";
import {ActiveStep, RequestState} from "modules";
import {
	CheckboxFormField,
	CheckboxLabel,
	Exist,
	Form,
	FormField,
	FormWrapper,
	Input,
	PrimaryButton,
	ErrorText,
	SubTitle,
} from "components";
import {clearGameError, saveUserAnswers, setActiveStep} from "modules/actions";
import {useTranslation} from "react-i18next";
import {createConnextraScriptTag, getValueByLang} from "modules/utils";
import {Checkbox, FormControlLabel} from "@mui/material";
import {ReactComponent as CheckBoxIcon} from "assets/images/icons/checkbox.svg";
import {ReactComponent as CheckedCheckBoxIcon} from "assets/images/icons/checked-checkbox.svg";

const Title = styled.div`
	font-size: 18px;
	font-family: "Hind", sans-serif;
	font-weight: 700;
	width: 100%;
	margin-bottom: 16px;
	color: ${({theme}) => theme.color.primary};
`;

const Button = styled(PrimaryButton)`
	max-width: 300px;
`;

const Question = styled.div`
	font-family: "Hind", sans-serif;
	width: 100%;
`;

const QuestionTitle = styled.div`
	font-weight: 600;
	margin-bottom: 5px;
	text-indent: -15px;
	padding-left: 15px;
	color: ${({theme}) => theme.color.primary};
`;

const QuestionAnswer = styled.div`
	margin-bottom: 16px;
	padding-left: 15px;
`;

const EditButton = styled.button`
	font-family: "Hind", sans-serif;
	background: transparent;
	appearance: none;
	outline: none;
	display: block;
	cursor: pointer;
	border: none;
	padding: 0;
	margin-left: auto;
	font-size: 16px;
	color: ${({theme}) => theme.color.secondary};
	font-weight: 500;
`;

export const EmailForm: FC = () => {
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const contest = useSelector(getContest);
	const {requestState, gameData} = useSelector(getGameState);
	const errors = useSelector(getErrors);
	const [isValid, setIsValid] = useState(false);
	const isError = !isEmpty(errors);
	const isLoading = requestState === RequestState.Requested;
	const isDisabled = isLoading || !isValid || isError;
	const lang = i18n.language;

	useEffect(() => {
		createConnextraScriptTag(
			"https://zz.connextra.com/dcs/tagController/tag/8ce716f59752/f2p_preseasonfutures_submission"
		);
	}, []);

	const handleSubmit = useCallback(
		(e: SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();

			const {email, notification}: Record<string, HTMLInputElement> = e.currentTarget;

			if (!contest) {
				return;
			}

			dispatch(
				saveUserAnswers({
					email: email.value,
					notification: notification.checked,
					lang,
				})
			);
		},
		[dispatch, contest, lang]
	);

	const handleOnChange = useCallback(
		(e: SyntheticEvent<HTMLFormElement>) => {
			const {email}: Record<string, HTMLInputElement> = e.currentTarget;

			const validateList = [email.value.trim()];

			setIsValid(validateList.every((el) => !!el));
			dispatch(clearGameError({}));
		},
		[dispatch, setIsValid]
	);

	const getAnswer = (id: number): string | undefined => {
		if (!contest || !gameData) {
			return;
		}

		const pick = gameData.find(({questionId}) => questionId === id);
		const question = contest.questions.find((question) => question.id === id);

		if (!question || !pick) {
			return;
		}

		const option = question.options.find(({id}) => id === pick.optionId);

		if (!option) {
			return;
		}

		return option.title[lang];
	};

	const editPickHandler = useCallback(() => {
		dispatch(clearGameError({}));
		dispatch(setActiveStep(ActiveStep.PREDICTION));
	}, [dispatch]);

	if (!contest) {
		return null;
	}

	return (
		<FormWrapper>
			<Form onSubmit={handleSubmit} onChange={handleOnChange}>
				<Title>{t("email.title")}</Title>

				{contest.questions.map(({title, id}, i) => (
					<Question key={id}>
						<QuestionTitle>{`${i + 1}. ${getValueByLang(title, lang)}`}</QuestionTitle>
						<QuestionAnswer>
							{t("email.your_picks")} {getAnswer(id)}
						</QuestionAnswer>
					</Question>
				))}

				<EditButton onClick={editPickHandler}>{t("email.edit_pick")}</EditButton>

				<hr />

				<SubTitle>{t("email.enter_email")}</SubTitle>

				<FormField hiddenLabel={true}>
					<Input
						variant="filled"
						required
						error={!!errors.email}
						name="email"
						type="email"
						// Disabled per task request
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={true}
						placeholder={t("email.add_email")}
						helperText={errors.email}
					/>
				</FormField>

				<CheckboxFormField>
					<FormControlLabel
						control={
							<Checkbox
								name="notification"
								icon={<CheckBoxIcon />}
								checkedIcon={<CheckedCheckBoxIcon />}
							/>
						}
						label={<CheckboxLabel>{t("email.notification")}</CheckboxLabel>}
					/>
				</CheckboxFormField>

				<Button type="submit" disabled={isDisabled}>
					{t("email.button")}
				</Button>

				<Exist when={!!errors[""]}>
					<ErrorText>{t(errors[""])}</ErrorText>
				</Exist>
			</Form>
		</FormWrapper>
	);
};
