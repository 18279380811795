export enum LinksType {
	FIRST,
	SECOND,
	WITH_IMAGE,
	EMPTY,
}

export const GAME_ZONE_URL = "https://gamezone.cfl.ca/";

export const links = {
	en: [
		{
			title: "nav.news.title",
			url: "https://www.cfl.ca/news/",
			type: LinksType.SECOND,
			subLinks: [
				{
					title: "nav.news.headlines",
					urls: [
						{
							title: "nav.news.diversity",
							url: "https://www.cfl.ca/dis/",
						},
						{
							title: "nav.news.insight",
							url: "https://www.cfl.ca/#:~:text=INSIGHT%20AND%20ANALYSIS",
						},
						{
							title: "nav.news.transactions",
							url: "https://www.cfl.ca/transactions/",
						},
						{
							title: "nav.news.injury_report",
							url: "https://www.cfl.ca/category/injury-report",
						},
						{
							title: "nav.news.power_rankings",
							url: "https://www.cfl.ca/category/power-rankings-2",
						},
						{
							title: "nav.news.the_snap",
							url: "https://www.cfl.ca/category/the-snap/",
						},
					],
				},
				{
					title: "nav.news.beyond_the_headlines",
					urls: [
						{
							title: "nav.news.cfl_ca_writers_contributors",
							url: "https://www.cfl.ca/cfl-ca-writers-contributors/",
						},
						{
							title: "nav.news.all_decade_team",
							url: "https://www.cfl.ca/2020/11/19/votes-cfl-unveils-decade-team/",
						},
						{
							title: "nav.news.football_operations",
							url: "https://www.cfl.ca/football-operations/",
						},
						{
							title: "nav.news.cfl_draft",
							url: "https://www.cfl.ca/draft-tracker/",
						},
					],
				},
			],
		},
		{
			title: "nav.video.title",
			url: "https://www.cfl.ca/videos/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.video.highlights",
					url: "https://www.cfl.ca/category/highlights/",
				},
				{
					title: "nav.video.game_recaps",
					url: "https://www.cfl.ca/videos/2021-game-recaps/",
				},
				{
					title: "nav.video.interviews",
					url: "https://www.cfl.ca/videos/interviews/",
				},
				{
					title: "nav.video.grey_cup_on_demand",
					url: "https://www.cfl.ca/grey-cup-on-demand/",
				},
				{
					title: "nav.video.cfl_in_40_presented",
					url: "https://www.cfl.ca/2020/11/02/cfl-cdic-team-re-air-classic-post-season-games/",
				},
				{
					title: "nav.video.cfl_live",
					url: "https://www.cfl.ca/live/",
				},
			],
		},
		{
			title: "nav.schedule.title",
			url: "https://www.cfl.ca/schedule/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.schedule.schedule",
					url: "https://www.cfl.ca/schedule/2022/",
				},
				{
					title: "nav.schedule.internation_streaming",
					url: "https://cfl.yaretv.com/",
				},
			],
		},
		{
			title: "nav.standings.title",
			url: "https://www.cfl.ca/standings/2022/",
			type: LinksType.EMPTY,
		},
		{
			title: "nav.stats.title",
			url: "https://www.cfl.ca/stats/?season=2022",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.stats.player_stats",
					url: "https://www.cfl.ca/stats/?season=2022",
				},
				{
					title: "nav.stats.team_stats",
					url: "https://www.cfl.ca/team-stats/?season=2022",
				},
				{
					title: "nav.stats.league_leaders",
					url: "https://www.cfl.ca/league-leaders/?season=2022",
				},
				{
					title: "nav.stats.cfl_rule_book",
					url: "https://d3ham790trbkqy.cloudfront.net/wp-content/uploads/2021/10/2021-Rules-Book-Final.pdf",
				},
				{
					title: "nav.stats.cfl_guide_book",
					url: "https://d3ham790trbkqy.cloudfront.net/wp-content/uploads/2021/08/2021-CFL-Guide.pdf",
				},
				{
					title: "nav.stats.statistics",
					url: "https://d3ham790trbkqy.cloudfront.net/wp-content/uploads/2015/11/2021_CFL_League_Statistics_Wk_16_Final.pdf",
				},
			],
		},
		{
			title: "nav.players.title",
			url: "https://www.cfl.ca/players/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.players.all_players",
					url: "https://www.cfl.ca/players/",
				},
				{
					title: "nav.players.bc_lions",
					url: "https://www.bclions.com/roster/",
				},
				{
					title: "nav.players.edmonton_elks",
					url: "https://www.esks.com/roster/",
				},
				{
					title: "nav.players.calgary_stapmpeders",
					url: "https://www.stampeders.com/roster/",
				},
				{
					title: "nav.players.saskatchewan_roughriders",
					url: "https://www.riderville.com/roster/",
				},
				{
					title: "nav.players.bluebombers",
					url: "https://www.bluebombers.com/roster/",
				},
				{
					title: "nav.players.tiger_cats",
					url: "https://ticats.ca/roster/",
				},
				{
					title: "nav.players.toronto_argonauts",
					url: "https://www.argonauts.ca/roster/",
				},

				{
					title: "nav.players.ottawa_redblacks",
					url: "https://www.ottawaredblacks.com/roster/",
				},
				{
					title: "nav.players.montreal_alouettes",
					url: "https://en.montrealalouettes.com/roster/",
				},
			],
		},
		{
			title: "nav.tickets.title",
			url: "https://www.cfl.ca/tickets",
			type: LinksType.WITH_IMAGE,
			image: "https://d3ham790trbkqy.cloudfront.net/wp-content/uploads/2021/08/PremiumExperiencesBox_2021_ENG.jpg",
			imageUrl: "https://www.cfl.ca/premium/",
			subLinks: [
				{
					title: "nav.tickets.west",
					urls: [
						{
							title: "nav.tickets.bclions",
							url: "https://www.bclions.com/2021-tickets/",
						},
						{
							title: "nav.tickets.stampeders",
							url: "https://www.stampeders.com/seasontickets/",
						},
						{
							title: "nav.tickets.edmonton",
							url: "https://www.esks.com/season_seats/",
						},
						{
							title: "nav.tickets.saskatchewan",
							url: "https://www.riderville.com/2021-season-ticket-intro/?utm_source=cfl&utm_medium=banner&utm_campaign=2021_STH_TICKETS",
						},
						{
							title: "nav.tickets.bluebombers",
							url: "https://www.bluebombers.com/2019-season-tickets",
						},
					],
				},
				{
					title: "nav.tickets.east",
					urls: [
						{
							title: "nav.tickets.hamilton_tiger_cats",
							url: "https://ticats.ca/season-seats",
						},
						{
							title: "nav.tickets.toronto_argonauts",
							url: "https://www.argonauts.ca/tickets/",
						},
						{
							title: "nav.tickets.ottawa_redblacks",
							url: "https://www.ottawaredblacks.com/season-seat-memberships/",
						},
						{
							title: "nav.tickets.montreal_alouettes",
							url: "https://en.montrealalouettes.com/tickets/",
						},
					],
				},
				{
					title: "nav.tickets.special_offers",
					withLine: true,
					urls: [
						{
							title: "nav.tickets.touchdown_atlantic",
							url: "https://www.cfl.ca/tda22/",
						},
						{
							title: "nav.tickets.premium_experiences",
							url: "https://www.cfl.ca/premium/?utm_source=cfl.ca&utm_campaign=Premium%20Experiences",
						},
					],
				},
			],
		},
		{
			title: "nav.shop.title",
			url: "https://www.cfl.ca/cfl-shop/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.shop.grey_cup_fan_base",
					url: "https://www.greycupfanbase.ca/",
				},
				{
					title: "nav.shop.bclions",
					url: "https://store.bclions.com/",
				},
				{
					title: "nav.shop.elks",
					url: "https://shop.goelks.com/",
				},
				{
					title: "nav.shop.stampeders",
					url: "https://store.stampeders.com/",
				},
				{
					title: "nav.shop.the_rider_store",
					url: "https://www.theriderstore.ca/",
				},
				{
					title: "nav.shop.the_bomber_store",
					url: "https://www.thebomberstore.com/",
				},
				{
					title: "nav.shop.the_tiger_cats",
					url: "https://shop.ticats.ca/",
				},
				{
					title: "nav.shop.argos",
					url: "https://shop.realsports.ca/pages/toronto-argonauts",
				},
				{
					title: "nav.shop.redblacks",
					url: "https://www.tdplaceteamshop.ca/pages/redblacks",
				},
				{
					title: "nav.shop.alouettes",
					url: "https://boutique.montrealalouettes.com/us/",
				},
			],
		},
		{
			title: "nav.forums.title",
			url: "https://forums.cfl.ca/",
			type: LinksType.EMPTY,
		},
		{
			title: "nav.draft.title",
			url: "https://www.cfl.ca/cfldraft22/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.draft.draft_tracker",
					url: "https://www.cfl.ca/%20draft%20tracker",
				},
				{
					title: "nav.draft.global_draft_tracker",
					url: "https://www.cfl.ca/2022-global-draft-tracker/",
				},
				{
					title: "nav.draft.mock_draft",
					url: "https://www.cfl.ca/2022/04/12/mock-1-0-what-will-edmonton-do-with-the-first-overall-pick/",
				},
				{
					title: "nav.draft.where_to_watch_cfl_draft",
					url: "https://www.cfl.ca/2022/04/19/where-to-watch-2022-cfl-draft-broadcast-info/",
				},
				{
					title: "nav.draft.news_and_notes",
					url: "https://www.cfl.ca/cfldraft22/",
				},
			],
		},
		{
			title: "nav.gamezone.title",
			url: GAME_ZONE_URL,
			type: LinksType.EMPTY,
		},
	],
	fr: [
		{
			title: "nav.news.title",
			url: "https://www.lcf.ca/nouvelles/",
			type: LinksType.SECOND,
			subLinks: [
				{
					title: "nav.news.headlines",
					urls: [
						{
							title: "nav.news.videos",
							url: "https://www.lcf.ca/videos/",
						},
						{
							title: "nav.news.chroniques",
							url: "https://www.lcf.ca/category/nouvelles/chroniques/",
						},
						{
							title: "nav.news.etat_des_forces",
							url: "https://www.lcf.ca/category/nouvelles/etat-des-forces/",
						},
						{
							title: "nav.news.notes_de_match",
							url: "https://www.lcf.ca/category/nouvelles/notes-de-match/",
						},
						{
							title: "nav.news.joueurs_de_la_semaine",
							url: "https://www.lcf.ca/category/nouvelles/joueurs-de-la-semaine/",
						},
						{
							title: "nav.news.joueurs_du_mois",
							url: "https://www.lcf.ca/category/nouvelles/joueurs-du-mois/",
						},
						{
							title: "nav.news.transactions",
							url: "https://www.cfl.ca/transactions/",
						},
					],
				},
				{
					title: "nav.news.all_video",
					urls: [
						{
							title: "nav.news.cfl_repechage_de_la_lcf",
							url: "https://www.lcf.ca/tableau-du-repechage/",
						},
						{
							title: "nav.news.cfl_repechage_mondial_de_la_lcf",
							url: "https://www.lcf.ca/radar-du-repechage-mondial-2022/",
						},
						{
							title: "nav.news.equipe_de_la_decennie",
							url: "https://www.lcf.ca/category/nouvelles/equipe-de-la-decennie/",
						},
					],
				},
				{
					title: "nav.news.covid19",
					urls: [
						{
							title: "nav.news.tests",
							url: "https://www.lcf.ca/2021/06/17/tests-de-depistage-rapide-de-covid-19-parmi-leventail-de-mesures-place-lors-retour-de-lcf-2/",
						},
						{
							title: "nav.news.results",
							url: "https://www.lcf.ca/2021/11/25/protocoles-matiere-de-covid-19-ont-aide-lcf-a-mettre-fin-a-saison-reguliere-couronnee-de-succes/",
						},
						{
							title: "nav.news.politics",
							url: "https://www.lcf.ca/2021/08/03/lcf-va-de-lavant-afin-de-prevenir-lannulation-de-match-raison-de-covid-19/",
						},
					],
				},
			],
		},
		{
			title: "nav.video.title",
			url: "https://www.lcf.ca/videos/",
			type: LinksType.SECOND,
			subLinks: [
				{
					title: "nav.video.all_video",
					urls: [
						{
							title: "nav.video.resumes",
							url: "https://www.lcf.ca/videos/resumes-de-match-mobile/",
						},
						{
							title: "nav.video.faits_saillants_de_joueurs",
							url: "https://www.lcf.ca/videos/faits-saillants-de-joueurs/",
						},
						{
							title: "nav.video.jeux_semaine",
							url: "https://www.lcf.ca/videos/jeux-semaine/",
						},
						{
							title: "nav.video.grey_cup_on_demand",
							url: "https://www.cfl.ca/grey-cup-on-demand/",
						},
					],
				},
			],
		},
		{
			title: "nav.schedule.title",
			url: "https://www.lcf.ca/calendrier/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.schedule.schedule",
					url: "https://www.lcf.ca/calendrier/2022/",
				},
				{
					title: "nav.schedule.diffusion",
					url: "https://www.lcf.ca/diffusion/",
				},
			],
		},
		{
			title: "nav.standings.title",
			url: "https://www.lcf.ca/classement/",
			type: LinksType.EMPTY,
		},
		{
			title: "nav.stats.title",
			url: "https://www.lcf.ca/stats/",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.stats.statistiques_individuelles",
					url: "https://www.lcf.ca/statistiques-individuelles/",
				},
				{
					title: "nav.stats.statistiques_equipes_lcf",
					url: "https://www.lcf.ca/statistiques-equipes-lcf/",
				},
				{
					title: "nav.stats.individuelles",
					url: "https://www.lcf.ca/stats/",
				},
			],
		},
		{
			title: "nav.players.title",
			url: "https://www.lcf.ca/joueurs/",
			type: LinksType.SECOND,
			subLinks: [
				{
					title: "nav.players.joueurs",
					urls: [
						{
							title: "nav.players.bc_lions",
							url: "https://www.bclions.com/roster/",
						},
						{
							title: "nav.players.edmonton_elks",
							url: "https://www.esks.com/roster/",
						},
						{
							title: "nav.players.calgary_stapmpeders",
							url: "https://www.stampeders.com/roster/",
						},
						{
							title: "nav.players.saskatchewan_roughriders",
							url: "https://www.riderville.com/roster/",
						},
						{
							title: "nav.players.bluebombers",
							url: "https://www.bluebombers.com/roster/",
						},
						{
							title: "nav.players.tiger_cats",
							url: "https://ticats.ca/roster/",
						},
						{
							title: "nav.players.toronto_argonauts",
							url: "https://www.argonauts.ca/roster/",
						},

						{
							title: "nav.players.ottawa_redblacks",
							url: "https://fr.ottawaredblacks.com/alignment/",
						},
						{
							title: "nav.players.montreal_alouettes",
							url: "https://www.montrealalouettes.com/alignement/",
						},
					],
				},
			],
		},
		{
			title: "nav.tickets.title",
			url: "https://www.lcf.ca/billets/",
			type: LinksType.WITH_IMAGE,
			image: "https://d3ham790trbkqy.cloudfront.net/wp-content/uploads/sites/11/2021/08/PremiumExperienceBox_2021_FRE.jpg",
			imageUrl: "https://www.lcf.ca/superieures/",
			subLinks: [
				{
					title: "nav.tickets.east",
					urls: [
						{
							title: "nav.tickets.billets_de_matchs",
							url: "https://www.montrealalouettes.com/billets-de-matchs/",
						},
						{
							title: "nav.tickets.ottawaredblacks",
							url: "https://fr.ottawaredblacks.com/billet/",
						},
						{
							title: "nav.tickets.argonauts",
							url: "https://www.argonauts.ca/tickets/",
						},
						{
							title: "nav.tickets.ticats",
							url: "https://ticats.ca/season-seats",
						},
					],
				},
				{
					title: "nav.tickets.west",
					urls: [
						{
							title: "nav.tickets.bluebombers",
							url: "https://www.bluebombers.com/tickets/",
						},
						{
							title: "nav.tickets.riderville",
							url: "https://www.riderville.com/tickets/",
						},
						{
							title: "nav.tickets.stampeders",
							url: "https://www.stampeders.com/tickets/",
						},
						{
							title: "nav.tickets.elks",
							url: "https://www.goelks.com/season_seats/",
						},
						{
							title: "nav.tickets.bclions",
							url: "https://www.bclions.com/2021-tickets/",
						},
					],
				},
				{
					title: "nav.tickets.special_offers",
					withLine: true,
					urls: [
						{
							title: "nav.tickets.premium_experiences",
							url: "https://www.lcf.ca/superieures/",
						},
					],
				},
			],
		},
		{
			title: "nav.shop.title",
			url: "https://www.lcf.ca/",
			type: LinksType.SECOND,
			subLinks: [
				{
					title: "nav.shop.subtitle",
					urls: [
						{
							title: "nav.shop.montreal_alouettes",
							url: "https://boutique.montrealalouettes.com/fc/",
						},
						{
							title: "nav.shop.redblacks",
							url: "https://www.tdplaceteamshop.ca/pages/redblacks",
						},
						{
							title: "nav.shop.toronto_argonauts",
							url: "https://shop.realsports.ca/pages/toronto-argonauts",
						},
						{
							title: "nav.shop.the_tiger_cats",
							url: "https://shop.ticats.ca/",
						},
						{
							title: "nav.shop.the_bomber_store",
							url: "https://www.thebomberstore.com/",
						},
						{
							title: "nav.shop.the_rider_store",
							url: "https://www.theriderstore.ca/",
						},
						{
							title: "nav.shop.stampeders",
							url: "https://store.stampeders.com/",
						},
						{
							title: "nav.shop.elks",
							url: "https://shop.goelks.com/",
						},
						{
							title: "nav.shop.bclions",
							url: "https://store.bclions.com/",
						},
					],
				},
			],
		},
		{
			title: "nav.draft.title",
			url: "https://www.lcf.ca/#",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.draft.tableau_du_repechage",
					url: "https://www.lcf.ca/tableau-du-repechage/",
				},
				{
					title: "nav.draft.tableau_du_repechage_mondial",
					url: "https://www.lcf.ca/radar-du-repechage-mondial-2022/",
				},
				{
					title: "nav.draft.bureau_de_recrutement",
					url: "https://www.lcf.ca/2022/04/19/bureau-de-recrutement-metchie-iii-se-hisse-au-sommet-du-classement/",
				},
				{
					title: "nav.draft.repechage_simule_2_0",
					url: "https://www.lcf.ca/2022/04/26/repechage-simule-2-0-a-quoi-ressemble-le-tableau-a-une-semaine-de-lencan/",
				},
				{
					title: "nav.draft.resultats",
					url: "https://www.lcf.ca/resultats-des-camps-devaluation-2022/",
				},
				{
					title: "nav.draft.videos",
					url: "https://www.lcf.ca/videos/espoirs",
				},
			],
		},
		{
			title: "nav.plus.title",
			url: "https://www.lcf.ca/#",
			type: LinksType.FIRST,
			subLinks: [
				{
					title: "nav.plus.socle_partisans_coupe_grey",
					url: "https://www.soclepartisanscoupegrey.ca/",
				},
				{
					title: "nav.plus.fantasy_lcf",
					url: "https://fantasylcf.rds.ca/",
				},
				{
					title: "nav.plus.predictions",
					url: "https://jeudepredictions.lcf.ca/",
				},
				{
					title: "nav.plus.football_delirant",
					url: "https://www.lcf.ca/footballdelirant/",
				},
				{
					title: "nav.plus.recompenses",
					url: "https://www.lcf.ca/recompenses/",
				},
			],
		},
		{
			title: "nav.gamezone.title",
			url: GAME_ZONE_URL,
			type: LinksType.EMPTY,
		},
	],
};

export interface ISubLinkItem {
	title: string;
	url: string;
}

export interface ISubLinks {
	title: string;
	urls: ISubLinkItem[];
	withLine?: boolean;
}

export interface IFirstItem {
	title: string;
	url: string;
	type: LinksType.FIRST;
	subLinks: ISubLinkItem[];
}

export interface ISecondItem {
	title: string;
	url: string;
	type: LinksType.SECOND;
	subLinks: ISubLinks[];
}

export interface IEmptyItem {
	title: string;
	url: string;
	type: LinksType.EMPTY;
}

export interface IWithImageItem {
	title: string;
	url: string;
	image: string;
	imageUrl: string;
	type: LinksType.WITH_IMAGE;
	subLinks: ISubLinks[];
}
