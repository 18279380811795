import styled from "styled-components";

export const FormWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 0 20px;
	width: 100%;
`;

export const Form = styled.form`
	text-align: left;
	width: 100%;
	padding: 30px 40px;
	border-radius: 4px;
	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 1px 3px 0px rgb(0 0 0 / 12%);
	display: flex;
	align-items: center;
	flex-direction: column;
	background: ${({theme}) => theme.background.form};
	color: ${({theme}) => theme.color.form};

	hr {
		width: 100%;
		margin: 8px 0;
	}

	@media (min-width: 769px) {
		max-width: 400px;
	}
`;
