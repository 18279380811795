import {SagaIterator} from "redux-saga";
import {all, takeLatest} from "redux-saga/effects";
import {getIsFriendlyCountry, requestData, saveUserAnswers} from "modules/actions";
import {requestDataSaga} from "./json";
import {getIsFriendlyCountrySaga, saveUserAnswersSaga} from "./game";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		/**
		 * JSON
		 */
		takeLatest(requestData, requestDataSaga),
		/**
		 * Game
		 */
		takeLatest(saveUserAnswers, saveUserAnswersSaga),
		takeLatest(getIsFriendlyCountry, getIsFriendlyCountrySaga),
	]);
};

export * from "./json";
