import {FC, useCallback, useState} from "react";
import {IFirstItem, ISecondItem, ISubLinks, Language, links, LinksType} from "modules";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";
import AnimateHeight from "react-animate-height";
import styled from "styled-components";
import iconNavShrink from "assets/images/icons/icon_nav_shrink.png";
import iconNavExpand from "assets/images/icons/icon_nav_expand.png";

const ItemWrapper = styled.div`
	margin-top: 2px;
	list-style: none;
`;

const ItemTitle = styled.div<{isOpen: boolean; $hideImage?: boolean}>`
	display: block;
	text-transform: uppercase;
	font-family: Khand, sans-serif;
	letter-spacing: 0.1em;
	width: 100%;
	line-height: 1;
	font-weight: 500;
	padding: 12px 10px 12px 20px;
	color: #fff;
	z-index: 2;
	font-size: 18px;
	background-color: #2b2b2b;
	transition: all 0.3s ease-out;

	${({$hideImage, isOpen}) =>
		!$hideImage &&
		`
		background-image: url(${isOpen ? iconNavShrink : iconNavExpand});
		background-repeat: no-repeat;
		background-position: right 10px center;
	`}

	&:active {
		background-color: ${({theme}) => theme.background.mobileLinkActive};
	}
`;

const SubLinks = styled.div``;

const SubLinksItem = styled.div`
	margin-top: 2px;
	list-style: none;
	overflow-wrap: break-word;

	img {
		width: 100%;
	}
`;

const Link = styled.a<{isOpen?: boolean; $withImage?: boolean}>`
	box-shadow: 0 2px #2b2b2b;
	margin-left: 30px;
	margin-right: 77px;
	width: 80%;
	padding: 12px 10px 12px 0;

	display: block;
	text-decoration: none;
	text-transform: uppercase;
	font-family: Khand, sans-serif;
	letter-spacing: 0.1em;
	line-height: 1;
	font-weight: 500;

	${({$withImage, isOpen}) =>
		$withImage &&
		`
		background-image: url(${isOpen ? iconNavShrink : iconNavExpand});
		background-repeat: no-repeat;
		background-position: right 10px center;
	`}
`;

export const Item: FC<typeof links[Language][number]> = (el) => {
	const {t} = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const toggleHandler = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	return (
		<ItemWrapper>
			<ItemTitle isOpen={isOpen} onClick={toggleHandler} $hideImage={isEmpty(el?.subLinks)}>
				{t(el.title)}
			</ItemTitle>

			<AnimateHeight height={isOpen ? "auto" : 0}>
				{el.type === LinksType.FIRST && (
					<SubLinks>
						<Link href={el.url}>{t(el.title)}</Link>
						{(el as IFirstItem).subLinks.map(({title, url}) => (
							<SubLinksItem key={url}>
								<Link href={url}>{t(title)}</Link>
							</SubLinksItem>
						))}
					</SubLinks>
				)}

				{(el.type === LinksType.SECOND || el.type === LinksType.WITH_IMAGE) && (
					<SubLinks>
						<Link href={el.url}>{t(el.title)}</Link>

						{(el as ISecondItem).subLinks.map((el) => (
							<SubItem key={el.title} {...el} />
						))}

						{el.image && (
							<SubLinksItem>
								<Link href={el.imageUrl}>
									<img src={el.image} alt="" />
								</Link>
							</SubLinksItem>
						)}
					</SubLinks>
				)}
			</AnimateHeight>
		</ItemWrapper>
	);
};

const SubItemSubLinksItem = styled(SubLinksItem)`
	margin: 0 10px;
`;

const SubItem: FC<ISubLinks> = (el) => {
	const {t} = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const toggleHandler = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	return (
		<ItemWrapper>
			<Link isOpen={isOpen} $withImage onClick={toggleHandler}>
				{t(el.title)}
			</Link>

			<AnimateHeight height={isOpen ? "auto" : 0}>
				<SubLinks>
					{el.urls.map(({url, title}) => (
						<SubItemSubLinksItem key={url}>
							<Link href={url}>{t(title)}</Link>
						</SubItemSubLinksItem>
					))}
				</SubLinks>
			</AnimateHeight>
		</ItemWrapper>
	);
};
