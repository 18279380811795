import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getGameState = (store: IStore) => store.game;

export const getActiveStep = createSelector(getGameState, ({step}) => step);

export const getErrors = createSelector(getGameState, ({errors}) => errors);

export const getGameData = createSelector(getGameState, ({gameData}) => gameData);

export const getIsFriendlyCountryState = createSelector(
	getGameState,
	({isFriendlyCountry}) => isFriendlyCountry
);
