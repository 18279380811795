import {FC} from "react";

interface IIconProps {
	width?: number;
	height?: number;
	color?: string;
	onClick?: () => void;
	title?: string;
	className?: string;
}

export const Spinner: FC<IIconProps> = ({
	title = "Spinner",
	width = 120,
	height = 120,
	color = "#fff",
}) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 120 120" className="loader">
		<title>{title}</title>

		<circle className="internal-circle" cx="60" cy="60" r="30" stroke={color} />
		<circle className="external-circle" cx="60" cy="60" r="50" stroke={color} />
	</svg>
);

export default Spinner;
